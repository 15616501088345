<template>
  <div v-loading="containerLoading" class="publish">
    <el-tabs v-model="role" @tab-click="handleClick">
      <el-tab-pane label="发布给学生" name="student">
        <div class="row">
          <div class="label">学段：</div>
          <el-radio-group v-model="student.level" @change="levelChange">
            <el-radio
              v-for="(item, index) in options"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="row">
          <div class="label">入学年份：</div>
          <el-radio-group v-model="student.gradeId" @change="gradeChange">
            <el-radio
              v-for="(item, index) in gradeList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="checkbox">
          <el-checkbox-group v-model="form.stats">
            <el-checkbox label="1" :disabled="switchOne">
              <span>仅卷面分</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list1.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="2" :disabled="switchTwo">
              <span>卷面分及超越率</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list2.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="3">
              <span>知识点分析</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list5.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="4">
              <span>小题分</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list4.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="5">
              <span>等级分（等级设置）</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list3.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="6">
              <span>试卷夹</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list6.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="7">
              <span>学科雷达图</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list7.png" alt="" />
              </div>
            </el-checkbox>
            <el-checkbox label="8">
              <span>答题卡</span>
              <div class="img">
                <img src="@/assets/imgs/exam/list8.png" alt="" />
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-tab-pane>
      <el-tab-pane label="发布给老师" name="teacher">
        <div class="row">
          <div class="label">学段：</div>
          <el-radio-group v-model="teacher.level" @change="levelChange">
            <el-radio
              v-for="(item, index) in options"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="row">
          <div class="label">入学年份：</div>
          <el-radio-group v-model="teacher.gradeId" @change="gradeChange">
            <el-radio
              v-for="(item, index) in gradeList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </div>
        <div v-if="role === 'teacher' && form.results" class="row">
          <div class="label">成绩报表：</div>
          <el-checkbox-group v-model="form.results">
            <el-checkbox label="1">校领导</el-checkbox>
            <el-checkbox label="2">年级组长</el-checkbox>
            <el-checkbox label="3">学科组长</el-checkbox>
            <el-checkbox label="4">班主任</el-checkbox>
            <el-checkbox label="5">任课教师</el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-if="role === 'teacher' && form.analysis" class="row">
          <div class="label">分析报告：</div>
          <el-checkbox-group v-model="form.analysis">
            <el-checkbox label="1">校领导</el-checkbox>
            <el-checkbox label="2">年级组长</el-checkbox>
            <el-checkbox label="3">学科组长</el-checkbox>
            <el-checkbox label="4">班主任</el-checkbox>
            <el-checkbox label="5">任课教师</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="btn">
      <el-button v-loading="loading" type="primary" @click="submit"
        >保存</el-button
      >
      <el-button type="primary" @click="other">应用到其他</el-button>
    </div>
    <el-dialog
      title="应用到其他"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="row">
        <div class="label">学段：</div>
        <el-select
          v-model="dialogForm.level"
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="checkbox">
        <div class="operate">
          请选择入学年份：<el-button type="text" @click="allCheck"
            >全选</el-button
          ><el-button type="text" @click="reverse">反选</el-button
          ><el-button type="text" @click="cancle">取消</el-button>
        </div>
        <el-checkbox-group v-model="dialogForm.gradeIds">
          <el-checkbox
            v-for="(item, index) in dialogGradeList"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getGradeList } from "@/core/api/school/schoolgradeclass";
import { getParams, setParams } from "@/core/api/newOther";
import { levelOptions } from "@/core/util/constdata.js";
export default {
  name: "Publish",
  data() {
    const levelList = levelOptions();
    let initLevel = null;
    if (levelList.length) {
      initLevel = levelList[0].value;
    }
    return {
      role: "student",
      form: {
        stats: [],
        results: [],
        analysis: [],
      },
      allData: {},
      singleData: [],
      student: {
        level: initLevel,
        gradeId: "",
      },
      teacher: {
        level: initLevel,
        gradeId: "",
      },
      dialogVisible: false,
      gradeList: [],
      switchOne: false,
      switchTwo: false,
      loading: false,
      dialogForm: {
        level: initLevel,
        gradeIds: [],
      },
      containerLoading: false,
      dialogGradeList: [],
      options: [...levelList],
    };
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.role === "student") {
          if (this.form.stats.includes("1")) {
            this.switchOne = false;
            this.switchTwo = true;
          } else if (this.form.stats.includes("2")) {
            this.switchOne = true;
            this.switchTwo = false;
          } else {
            this.switchOne = false;
            this.switchTwo = false;
          }
        }
      },
    },
  },
  mounted() {
    this.getGradeList();
    this.getData();
  },
  methods: {
    async dialogSubmit() {
      this.containerLoading = true;
      if (this.dialogForm.gradeIds.length) {
        let arr = this.dialogForm.gradeIds.map((item) => {
          return {
            ...this.form,
            gradeId: item,
          };
        });
        let filterArr = this.singleData.filter(
          (item) => !this.dialogForm.gradeIds.includes(item.gradeId)
        );
        this.singleData = [...arr, ...filterArr];
        this.allData[this.role] = [...this.singleData];
        try {
          await setParams({
            key: 7,
            param: JSON.stringify({ ...this.allData }),
          });
          this.$message.success("提交成功");
          this.loading = false;
          this.dialogVisible = false;
          this.getData();
          this.containerLoading = false;
        } catch {
          this.loading = false;
          this.containerLoading = false;
        }
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择学段",
        });
      }
    },
    allCheck() {
      this.dialogForm.gradeIds = this.dialogGradeList.map((item) => item.id);
    },
    reverse() {
      let arr = this.dialogGradeList.map((item) => item.id);
      this.dialogForm.gradeIds = arr.filter(
        (item) => !this.dialogForm.gradeIds.includes(item)
      );
    },
    cancle() {
      this.dialogForm.gradeIds = [];
    },
    other() {
      this.dialogVisible = true;
      this.dialogForm.gradeIds = [];
      this.selectChange();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    levelChange() {
      this.getGradeList();
    },
    async selectChange() {
      const res = await getGradeList({
        level: this.dialogForm.level,
      });
      this.dialogGradeList = res.data.data;
    },
    gradeChange(val) {
      const data = this.singleData.find((item) => item.gradeId === val);
      if (data) {
        if (this.role === "student") {
          this.form = { stats: [], ...data };
        } else if (this.role === "teacher") {
          this.form = { results: [], analysis: [], ...data };
        }
      } else {
        if (this.role === "student") {
          let data = {
            gradeId: val,
            level: this.student.level,
            stats: [],
          };
          this.singleData.push(data);
          this.form = { stats: [], ...data };
        } else if (this.role === "teacher") {
          let data = {
            gradeId: val,
            level: this.student.level,
            results: [],
            analysis: [],
          };
          this.singleData.push(data);
          this.form = { ...data };
        }
      }

      if (this.student.level == 2) {
        this.form.stats = ["1"];
        this.switchOne = false;
        this.switchTwo = true;
      } else if (this.student.level == 3) {
        this.form.stats = ["2"];
        this.switchOne = true;
        this.switchTwo = false;
      }
    },
    async getData() {
      const res = await getParams({ key: 7 });
      if (res.data.data.length) {
        this.allData = { ...JSON.parse(res.data.data[0].param) };
      }
      this.handleClick();
    },
    handleClick() {
      if (this.allData[this.role] instanceof Array) {
        this.singleData = this.allData[this.role];
      }
      this.form = {
        results: [],
        analysis: [],
        stats: [],
      };
      this.getGradeList();
    },
    async submit() {
      this.loading = true;
      this.containerLoading = true;
      let param = null;
      if (this.role === "student") {
        param = this.student;
      } else if (this.role === "teacher") {
        param = this.teacher;
      }
      if (param.gradeId) {
        const index = this.singleData.findIndex(
          (item) => item.gradeId === this.form.gradeId
        );
        this.singleData[index] = { ...this.form };
        this.allData[this.role] = [...this.singleData];
        try {
          await setParams({
            key: 7,
            // param: JSON.stringify({ teacher: [], student: [] }),
            param: JSON.stringify({ ...this.allData }),
          });
          this.$message.success("提交成功");
          this.loading = false;
          this.dialogVisible = false;
          this.getData();
          this.containerLoading = false;
        } catch {
          this.loading = false;
          this.containerLoading = false;
        }
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: "请选择学段",
        });
      }
    },
    async getGradeList() {
      const res = await getGradeList({
        level: this.role == "student" ? this.student.level : this.teacher.level,
      });
      this.gradeList = res.data.data;
      if (res.data.data.length) {
        if (this.role === "student") {
          this.student.gradeId = res.data.data[0].id;
          this.gradeChange(res.data.data[0].id);
        } else if (this.role === "teacher") {
          this.teacher.gradeId = res.data.data[0].id;
          this.gradeChange(res.data.data[0].id);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.publish {
  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 28px;

    .label {
      font-size: 14px;
      color: #151716;
      margin-right: 10px;
      white-space: nowrap;
    }
  }
  .checkbox {
    margin-top: 30px;
  }
}
::v-deep .el-radio {
  margin-bottom: 0;
}
.img {
  height: 156px;
  box-sizing: border-box;
  padding: 8px;
  background-color: #f3f3f3;
  margin-top: 26px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
::v-deep .el-checkbox {
  display: flex;
  align-items: flex-start;
  margin-right: 30px;
  .el-checkbox__input {
    margin-top: 3px;
  }
}
::v-deep .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.btn {
  margin-top: 50px;
  text-align: center;
}
.operate {
  margin-bottom: 20px;
}
</style>
